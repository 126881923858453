  import React from 'react';
  import { Helmet, HelmetProvider } from 'react-helmet-async';
  import './App.css';
  import './teletext.css';
  import TeletextoHeader from './TeletextoHeader';
  import Header from './Header';
  import RetroTV from './RetroTV';
  import Section from './Section';

  const App = () => {
    const metaDescription = "Radio Televisión ESLA EKO - Emisora libre y comunitaria. Radio local con programas en directo, podcasts, programación y contenido bajo demanda.";
    
    return (
      <HelmetProvider>
        <div className="App">
        <Helmet>
          {/* Preconnect a dominios externos */}
          <link rel="preconnect" href="https://admin.radio.eslaeko.net" />
          <link rel="preconnect" href="https://chat.nodocarabanchel.net" />
        </Helmet>

        <main>
          <TeletextoHeader />
          <Header />
          <RetroTV /> 
          <Section 
            colorClass="section-en-directo" 
            title="RADIO EN DIRECTO" 
            src="https://admin.radio.eslaeko.net/public/radio_esla_eko/embed" 
            height="200px" 
            page="103"
            aria-label="Emisión en directo de Radio TV ESLA EKO"
          />
          <Section 
            colorClass="section-chat" 
            title="SALA DE CHAT" 
            src="https://chat.nodocarabanchel.net" 
            height="360px" 
            page="104"
            aria-label="Sala de chat de Radio Televisión ESLA EKO"
          />
          <Section 
            colorClass="section-anterior" 
            title="HISTORIAL DE PROGRAMAS" 
            src="https://admin.radio.eslaeko.net/public/radio_esla_eko/history" 
            height="360px" 
            page="105"
            aria-label="Historial de programas recientes"
          />
          <Section 
            colorClass="section-parrillas" 
            title="PROGRAMACIÓN" 
            src="https://admin.radio.eslaeko.net/public/radio_esla_eko/schedule/embed?locale=es" 
            height="360px" 
            page="106"
            aria-label="Programación semanal de Radio Televisión ESLA EKO"
          />
          <Section 
            colorClass="section-podcasts" 
            title="PODCAST" 
            src="https://admin.radio.eslaeko.net/public/radio_esla_eko/podcasts?embed=true" 
            height="360px" 
            page="107"
            aria-label="Programas grabados de Radio Televisión ESLA EKO"
          />
          <Section 
            colorClass="section-a-la-carta" 
            title="CONTENIDO BAJO DEMANDA" 
            src="https://admin.radio.eslaeko.net/public/radio_esla_eko/ondemand/embed" 
            height="360px" 
            page="108"
            aria-label="Contenido bajo demanda de Radio Televisión ESLA EKO"
          />
        </main>
      </div>
    </HelmetProvider>
  );
};

export default App;